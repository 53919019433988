import React from "react";
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import '../../components/Breadcrumb.css'
import Layout from "../../components/Layout";
import styled from "styled-components";

import BgImage1 from "../../img/news-bg.png";

const EatPage = ({pageContext}) => {
  const { breadcrumb: { crumbs }} = pageContext;
  return(
  <Layout>
    <Body>
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="ごはん予約"  />
      <MidashiBg>
        <Midashi>ごはん予約</Midashi>
      </MidashiBg>
      <form action="https://docs.google.com/forms/u/0/d/e/1FAIpQLSede0yFh7HjoGy0xat-7rs9scfQD_6Mo2z4fPrMnstasys5Ww/formResponse">
        <div>
        <label for="name">お名前</label><br />
        <InputText id="name" type="text" required="required" name="entry.1873073750"/>
        </div>
        <br />

        <div>
          <label for="email">メールアドレス</label><br />
          <InputText id="email" type="email" required="required" name="entry.576832196" />
        </div>
        <br />

        <div>
        <label for="tel">電話番号</label><br />
        <InputText id="tel" type="text" required="required" name="entry.854095305"/>
        </div>
        <br />

        <div>
        <label for="order">オーダー　</label>
        <input id="order" list="orderlist" name="entry.229311337"/>
        <datalist id="orderlist">
          <option value="ルーローハン ￥650" />
          <option value="豆カレー ￥650" />
          <option value="日替わりメニュー ￥880" />
        </datalist>
        </div>
        <br />

        <div>
        <label for="howmany">個数</label><br />
        <InputText id="howmany" type="text" name="entry.1809090623"/>
        </div>
        <br />

        <div>
        <label for="whattime">受取希望時間 (11:00-16:00)</label><br />
        <InputText id="whattime" type="text" name="entry.1405533421"/>
        </div>
        <br />

        <div>
        <label for="etc">備考欄</label><br />
        <InputText id="etc" type="text" name="entry.1761938598"/>
        </div>
        <br />

        <Button type="submit" value="送信">送信</Button>
      </form>
      <Breadcrumb crumbs={crumbs} crumbSeparator="/" crumbLabel="ごはん予約"  />
      </Body>
  </Layout>
);
}

export default EatPage;

const Body = styled.div`
  margin: 0 5vw 3em 5vw;
  text-align: left;
`;

const MidashiBg = styled.div`
  width: 100%;
  height: 15em;
  background-image: url(${BgImage1});
  padding: 5em 15vw 0 15vw;
  margin: 0 0 5em 0;
  background-repeat: no-repeat;
  background-position: 50% 100%;
  background-size: 100%;
  text-align: center;
`;

const Midashi = styled.p`
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 20pt;
`;

const InputText = styled.input`
width: 20em;
border: 0;
border-bottom: 1px solid #1b2538;
background-color: transparent;

  &:focus{
  transition: 0.4s;
  outline: none;
  }
`;

const Button = styled.button`
  background: #666666;
  width: 20vw;
  height: 10vh;
  margin: 5vh 0 10vh 0;
  border: none;
  border-radius: 10px;
  outline: none;
  font-family: 'Noto Sans JP', sans-serif;
  font-weight: 700;
  font-size: 14pt;
  color: #ffffff;

  &:hover {
    background: #f6f5f1;
    border: 1px #666666 solid;
    border-radius: 10px;
    color: #666666;
    pointer: cursor;
  }
`;
